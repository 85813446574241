<template>
  <q-form ref="editForm">
    <!--기본정보(LBLBASEINFO) -> 추가정보-->
    <c-card title="추가정보" class="cardClassDetailForm">
      <!-- <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="check"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveRegularCheck"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template> -->
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-danger-area
            ref="dangerArea"
            :editable="editable"
            :disabled="true"
            label="위험물 저장소"
            name="chmDangerMstId"
            v-model="check.chmDangerMstId"
            @detailInfo="detailInfo"
          ></c-danger-area>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="check"
            :disabled="true"
            label="안전관리자"
            type="user"
            name="chmDangerManageUserId"
            v-model="check.chmDangerManageUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="check"
            :disabled="true"
            label="담당자"
            type="user"
            name="chmDangerUserId"
            v-model="check.chmDangerUserId">
          </c-multi-field>
        </div>
      </template>
    </c-card>
    <c-table
      ref="tableType"
      title="저장품목"
      :columns="gridType.columns"
      :gridHeight="itemHeight"
      :data="check.checkitems"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="저장품목을 추가하세요."
      :hideBottom="true"
      rowKey="chmDangerRegularCheckItemId"
    >
      <!-- <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addType" />
          <c-btn v-if="editable && check.types && check.types.length > 0" :showLoading="false" label="LBLEXCEPT" icon="remove" @btnClicked="removeType" />
        </q-btn-group>
      </template> -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'calcuMethod'">
          {{props.row['calcuOriginal'] * props.row['calcuMultiple'] | toThousandFilter}}
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerMaintenanceDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
        chmStatusDangerRegularCheckCd: '',
        chmDangerMstTypeId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      check: {
        chmDangerRegularCheckId: '',
        chmDangerMstId: '',
        chmDangerMstTypeId: '',
        plantCd: '',
        chmDangerTypeCd: null,
        chmDangerLocation: '',
        chmDangerCheckName: '',
        chmDangerPermitNo: '',
        chmDangerUserId: '',
        chmDangerManageUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerInspectorUserId: '',
        chmStatusDangerRegularCheckCd: '',
        chmDangerStartDate: '',
        chmDangerEndDate: '',
        checkitems: [],
        deletecheckitems: [],
        period: [],
      },
      gridType: {
        columns: [
          {
            name: 'dangerItemName',
            field: 'dangerItemName',
            label: '품목',
            style: 'width:300px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'chmDangerTypeName',
            field: 'chmDangerTypeName',
            label: '품목 명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'calcuOriginal',
            field: 'calcuOriginal',
            label: '품목별 지정수량',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'calcuMultiple',
            field: 'calcuMultiple',
            label: '배수(사업장 허가)',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'calcuMethod',
            field: 'calcuMethod',
            label: '적용 지정수량',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
        ],
        height: '300px',
        data: [],
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveType: 'POST',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    itemHeight() {
      let height = this.contentHeight - 250;
      if (height < 300) {
        height = 100;
      }
      return String(height) + 'px';
    },
    disabled() {
      return this.check.chmStatusDangerRegularCheckCd === 'CSDRCC0002';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.danger.regularChecks.get.url
      this.saveUrl = transactionConfig.chm.danger.regularCheck.update.url;
      this.completeUrl = transactionConfig.chm.danger.regularCheck.complete.url;
      this.deleteUrl = transactionConfig.chm.danger.regularCheck.delete.url;
      // code setting
      // list setting
      this.getDetail()
    },
    getDetail() {
      if (this.popupParam.chmDangerRegularCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.chmDangerRegularCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.check = _result.data;
          this.check.period = [_result.data.chmDangerStartDate, _result.data.chmDangerEndDate];
        },);
      }
    },
    detailInfo(data) {
      if(!this.popupParam.chmDangerRegularCheckId){
        data = this.$_.extend(data, {
          chmDangerLocation: data.chmDangerArea,
          chmDangerPermitNo: data.permitNo,
          chmDangerManageUserId: data.chmDangerManageUserId,
          chmDangerUserId: data.chmDangerUserId
        })
        data.checkitems = this.$_.map(data.typeList, item =>{
          return this.$_.extend(item, {
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
        this.$_.extend(this.check, data)
      } else {
        data.checkitems = this.$_.map(data.typeList, item =>{
          return this.$_.extend(item, {
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
        this.$_.extend(this.check, data)
      }
    },
    // saveRegularCheck() {
    //   if (this.popupParam.chmDangerRegularCheckId) {
    //     this.saveUrl = transactionConfig.chm.danger.regularCheck.update.url;
    //     this.saveType = 'PUT';
    //   } else {
    //     this.saveUrl = transactionConfig.chm.danger.regularCheck.insert.url;
    //     this.saveType = 'POST';
    //   }
    //   this.$refs['editForm'].validate().then(_result => {
    //     if (_result) { 
    //       window.getApp.$emit('CONFIRM', {
    //         title: 'LBLCONFIRM',
    //         message: 'MSGSAVE', // 저장하시겠습니까?
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.check.regUserId = this.$store.getters.user.userId
    //           this.check.chgUserId = this.$store.getters.user.userId
    //           this.isSave = !this.isSave;
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // saveCallback() {
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    // },
  }
};
</script>
